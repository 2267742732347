import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "gatsby-image"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import { Link } from "gatsby"

export default class Template extends React.Component {
  componentDidMount = () => {}

  render() {
    const {
      data: {
        allMarkdownRemark: { edges },
      },
    } = this.props

    return (
      <Layout>
        <SEO title="All Services" />
        <Container className="d-flex justify-content-around flex-wrap">
          {edges.map(
            ({
              node: {
                id,
                frontmatter: { categoryName, path, image, hidden },
              },
            }) =>
              !hidden && (
                <Link
                  className="card my-3 pointer"
                  key={id}
                  to={path.replace("works", "direct")}
                >
                  <div className="card-header-image">
                    <Image
                      fluid={image.childImageSharp.fluid}
                      style={{ width: 400 }}
                    />
                  </div>
                  <div className="card-action">
                    <div className="btn btn-md btn-primary w-100 text-decoration-none">
                      {categoryName}
                    </div>
                  </div>
                </Link>
              )
          )}
        </Container>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___sortKey] }) {
      edges {
        node {
          id
          frontmatter {
            title
            articleHeading
            categoryID
            categoryName
            path
            hidden
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
